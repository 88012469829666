import Swal from "sweetalert2"
import axios from "axios"

import{
    trans,
    CZY_NA_PEWNO_CHCESZ_PRZELICZYC_PUNKTY,
    TAK_PRZELICZ,
    POPRAWNIE_PRZELICZONO,
    CZY_NA_PEWNO_ZAKONCZYC_AUDYT,
    ZAKONCZONEGO_AUDYTU_NIE_BEDZIE_MOZNA,
    TAK_ZAKONCZ_AUDYT,
    POPRAWNIE_ZAKONCZONO_AUDYT,
    ANULUJ,
    BLAD,
    SUKCES,
    ANULOWANO,
} from '../../../translator';

const loader = window.customLoader

const fillAuditStepOne = () => {
    $(() => {
      $('[data-toggle="tooltip"]').tooltip()
    })


    $(document).on('click', '.finishAudit', function (e) {

        e.preventDefault()

        const id = $(this).data('id'),
            edit = $(this).data('edit')

        let title, text, buttonText, successText

        if (edit)
        {
            title = trans(CZY_NA_PEWNO_CHCESZ_PRZELICZYC_PUNKTY)
            text = ''
            buttonText = trans(TAK_PRZELICZ)
            successText = trans(POPRAWNIE_PRZELICZONO)
        }
        else
        {
            title = trans(CZY_NA_PEWNO_ZAKONCZYC_AUDYT)
            text = trans(ZAKONCZONEGO_AUDYTU_NIE_BEDZIE_MOZNA)
            buttonText = trans(TAK_ZAKONCZ_AUDYT)
            successText = trans(POPRAWNIE_ZAKONCZONO_AUDYT)
        }

        Swal.fire({
            icon: 'question',
            title: title,
            text: text,
            allowOutsideClick: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: trans(ANULUJ),
            cancelButtonColor: '#ef5350',
            confirmButtonText: buttonText,
            confirmButtonColor: '#25b372',
        }).then( r => {
            if (r.isConfirmed)
            {
                loader.show()

                axios.patch(Routing.generate('audit_finish', {auditId: id})).then(r => r.data).then(data => {

                    let icon, title, text, redirect

                    if (data.error)
                    {
                        icon = 'error'
                        title = trans(BLAD)
                        text = data.error
                        redirect = false
                    }
                    else
                    {
                        icon = 'success'
                        title = trans(SUKCES)
                        text = successText
                        redirect = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: text,
                        allowOutsideClick: !redirect,
                        confirmButtonColor: '#25b372'
                    }).then( () => {

                        if (redirect)
                        {
                            window.location = Routing.generate('audit_list_auditor', {type: 1})
                        }

                    })

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO),
                    confirmButtonColor: '#25b372'
                }).then(null)
            }
        })

    })

}

window.initFillAuditStepOne = () => {
    fillAuditStepOne()
}