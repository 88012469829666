import { datatableInit } from "../functions/datatableInit";
import Swal from "sweetalert2";

import {
    trans,
    CZY_NA_PEWNO_USUNAC_SKALE,
    TAK_USUN,
    NIE_ANULUJ,
    SKALA_ZOSTALA_USUNIETA,
    NIE_UDALO_SIE_USUNAC_SKALI,
    ANULOWANO_USUWANIE_SKALI,
    ZAMKNIJ_BUTTON,

} from '../../../translator';

const loader = window.customLoader

const scaleList = () => {

    $(document).on('click', '.remove-scale', function () {
        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_USUNAC_SKALE) ,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            cancelButtonColor: '#6c757d'
        }).then(result => {
            console.log(result)
            if (result.isConfirmed)
            {
                $.ajax({
                    "beforeSend": function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_scale_remove', {id: id}),
                    "complete": function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(SKALA_ZOSTALA_USUNIETA),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then( () => {
                            location.reload();
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_SKALI),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_SKALI),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })

    $(() => {

        datatableInit('.datatable')

    })

}

window.initScaleList = () => {
    scaleList()
}