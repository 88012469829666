import { datatableInit } from "../functions/datatableInit";
import Swal from 'sweetalert2'

import {
    trans,
    CZY_NA_PEWNO_CHCESZ_USUNAC_CECHE_LOKALIZACJI,
    NIE_ANULUJ,
    TAK_USUN,
    CECHA_LOKALIZACJI_ZOSTALA_USUNIETA,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_USUNAC_CECHY_LOKALIZACJI,
    ANULOWANO_USUWANIE_CECHY_LOKALIZACJI,

} from '../../../translator';

const featureLocationList = () => {

    $(document).on('click', '.remove-features', function (e){
        e.preventDefault()
        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_CHCESZ_USUNAC_CECHE_LOKALIZACJI),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    type: 'post',
                    url: Routing.generate('features_remove', {id: id}),
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(CECHA_LOKALIZACJI_ZOSTALA_USUNIETA),
                            showCancelButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            cancelButtonText: trans(ZAMKNIJ_BUTTON),
                            cancelButtonColor: '#3f51b5',
                        }).then( () => {
                            location.reload()
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_CECHY_LOKALIZACJI),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_CECHY_LOKALIZACJI),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(() => {

        datatableInit('.datatable')

    })

}

window.initFeatureLocationList = () => {
    featureLocationList()
}