import Swal from "sweetalert2";
import {datatableInit} from "../functions/datatableInit";

import {
    trans,
    CZY_NA_PEWNO_CHCESZ_USUNAC_ANKIETE,
    NIE_ANULUJ,
    TAK_USUN,
    ANKIETA_ZOSTALA_USUNIETA,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_USUNAC_ANKIETY,
    ANULOWANO_USUWANIE_ANKIETY,
    CZY_NA_PEWNO_AKTUALIZOWAC_AUDYTY,
    TAK_AKTUALIZUJ,
    AUDYTY_ZOSTALY_ZAKTUALIZOWANE,
    NIE_UDALO_SIE_ZAKTUALIZOWAC_ANKIETY,
    ANULOWANO_AKTUALIZACJE_AUDYTOW,
    CZY_NA_PEWNO_ZMIENIC_STATUS,
    TAK_ZMIEN,
    STATUS_ANKIETY_ZOSTAL_ZMIENIONY,
    NIE_UDALO_SIE_ZMIENIC_STATUSU_ANKIETY,
    ANULOWANO_ZMIANE_STATUSU_ANKIETY,
    CZY_CHCESZ_ZMIENIC_PRIORYTET_WYSWIETLANIA,
    WPROWADZ_NOWY_PRIORYTET,
    PRIORYTET_WYSWIETLANIA_ANKIETY_ZOSTAL_ZMIENIONY,
    NIE_UDALO_SIE_ZMIENIC_PRIORYTETU_WYSWIETLANIA,
    ANULOWANO_ZMIANE_PRIORYTETU_WYSWIETLANIA,

} from '../../../translator';
const questionnaireList = () => {

    const loader = window.customLoader

    $(document).on('click','.remove-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_CHCESZ_USUNAC_ANKIETE),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(ANKIETA_ZOSTALA_USUNIETA),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_ANKIETY),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_ANKIETY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('click','.update-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_AKTUALIZOWAC_AUDYTY),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_AKTUALIZUJ),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('update_survey_list', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(AUDYTY_ZOSTALY_ZAKTUALIZOWANE),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_ZAKTUALIZOWAC_ANKIETY),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_AKTUALIZACJE_AUDYTOW),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('click','.edit-status-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_ZMIENIC_STATUS),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_ZMIEN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_edit_status', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(STATUS_ANKIETY_ZOSTAL_ZMIENIONY),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh").load(location.href + " #table-refresh")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_ZMIENIC_STATUSU_ANKIETY),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_ZMIANE_STATUSU_ANKIETY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })

    $(document).on('click','.edit-display-priority-questionnaire', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_CHCESZ_ZMIENIC_PRIORYTET_WYSWIETLANIA),
            text: trans(WPROWADZ_NOWY_PRIORYTET),
            input: 'number',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_ZMIEN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                if(result.value){

                    $.ajax({
                        beforeSend: function (){
                            loader.show()
                        },
                        type: 'post',
                        url: Routing.generate('surveys_survey_edit_display_priority', {id: id, val: result.value}),
                        complete: function (){
                            loader.hide()
                        }
                    }).done(d => {
                        if (!d.error)
                        {
                            Swal.fire({
                                icon: 'success',
                                title: trans(PRIORYTET_WYSWIETLANIA_ANKIETY_ZOSTAL_ZMIENIONY),
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                confirmButtonColor: '#3f51b5',
                            }).then(() => {
                                $("#table-refresh").load(location.href + " #table-refresh")
                            } )
                        }
                        else
                        {
                            Swal.fire({
                                icon: 'error',
                                title: trans(NIE_UDALO_SIE_ZMIENIC_PRIORYTETU_WYSWIETLANIA),
                                text: d.error,
                                confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                confirmButtonColor: '#3f51b5',
                            })
                        }

                    })
                }

            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_ZMIANE_PRIORYTETU_WYSWIETLANIA),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })


    $(() => {

        datatableInit('.datatable')

    })
}

window.initQuestionnaireList = () => {
    questionnaireList()
}