import { datatableInit } from "../functions/datatableInit"
import Swal from "sweetalert2"
import flatpickr from "flatpickr"
import {Polish} from "flatpickr/dist/l10n/pl"
import axios from "axios"

import {
    trans,
    WYBIERZ_DATE_ZAKONCZENIA,
    DATA_ZAKONCZENIA_TURY,
    ZMIEN_DATE_ZAKONCZENIA,
    ANULUJ,
    MUSISZ_WYBRAC_DATE,
    DATA_ZAKONCZENIA_MUSI_BYC_WIEKSZA_NIZ,
    BLAD,
    SUKCES,
    POPRAWNIE_ZMIENIONO_DATE_ZAKONCZENIA,
    BLAD_SERWERA,
    ANULOWANO,
    CZY_NA_PEWNO_CHCESZ_USUNAC_TURE,
    NIE_ANULUJ,
    TAK_USUN,
    TURA_ZOSTALA_USUNIETA,
    NIE_UDALO_SIE_USUNAC_TURY,
    ANULOWANO_USUWANIE_TURY,
    CZY_NA_PEWNO_ZMIENIC_STATUS_TURY,
    TAK_ZMIEN,
    STATUS_TURY_ZOSTAL_ZMIENIONY,
    NIE_UDALO_SIE_ZMIENIC_STATUSU_TURY,
    ANULOWANO_ZMIANE_STATUSU_TURY,
    PRIORYTET_WYSWIETLANIA_TURY_ZOSTAL_ZMIENIONY,
    NIE_UDALO_SIE_ZMIENIC_PRIORYTETU_WYSWIETLANIA_TURY,
    ANULOWANO_ZMIANE_PRIORYTETU_WYSWIETLANIA_TURY,
    CZY_CHCESZ_ZMIENIC_PRIORYTET_WYSWIETLANIA_TURY,
    WPROWADZ_NOWY_PRIORYTET


} from '../../../translator';
const loader = window.customLoader

const roundList = () => {

    $(() => {

        datatableInit('.datatable')

        $('.hasTooltip').tooltip()

    })

    $(document).on('click', '.date-edit', async function (e) {
        e.preventDefault()
        const roundId = $(this).data('round')
        const start = new Date(Number($(this).data('start')) * 1000)

        let flatPickerInstance

        Swal.fire({
            icon: 'question',
            title: trans(WYBIERZ_DATE_ZAKONCZENIA),
            input: 'text',
            inputLabel: trans(DATA_ZAKONCZENIA_TURY),
            allowOutsideClick: false,
            confirmButtonColor: '#25b372',
            confirmButtonText: trans(ZMIEN_DATE_ZAKONCZENIA),
            showCancelButton: true,
            cancelButtonColor: '#ef5350',
            cancelButtonText: trans(ANULUJ),
            willOpen: () => {
                flatPickerInstance = flatpickr(
                    Swal.getPopup().querySelector('.swal2-input'), {
                        locale: Polish
                    }
                )
            },
            didOpen: () => {
                Swal.getConfirmButton().focus()
            },
            preConfirm: () => {
                const dateEnd = new Date(Swal.getPopup().querySelector('.swal2-input').value)

                if (Swal.getPopup().querySelector('.swal2-input').value === '')
                {
                    Swal.showValidationMessage(trans(MUSISZ_WYBRAC_DATE))
                }

                if (dateEnd <= start) {
                    Swal.showValidationMessage(trans(DATA_ZAKONCZENIA_MUSI_BYC_WIEKSZA_NIZ))
                }

                return {
                    dateEnd: dateEnd
                }

            }

        }).then((res) => {

            if (res.isConfirmed)
            {
                loader.show()

                const data = new FormData()

                data.append('date', res.value.dateEnd.toJSON().slice(0, 10))

                axios.post(Routing.generate('round_edit_date', {id: roundId}), data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(r => {
                    const data = r.data
                    let icon, title, content, reload

                    if (data.error)
                    {
                        icon = 'error'
                        title = trans(BLAD)
                        content = data.error
                        reload = false
                    }
                    else
                    {
                        icon = 'success'
                        title = trans(SUKCES)
                        content = trans(POPRAWNIE_ZMIENIONO_DATE_ZAKONCZENIA)
                        reload = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: content,
                        allowOutsideClick: !reload
                    }).then( () => {

                        if (reload)
                        {
                            location.reload()
                        }

                    })
                }).catch( () => {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        title: trans(BLAD),
                        text: trans(BLAD_SERWERA)
                    }).then(null)
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO),
                    confirmButtonColor: '#25b372'
                }).then()
            }

        })

    })

    $(document).on('click','.remove-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_CHCESZ_USUNAC_TURE),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('rounds_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(TURA_ZOSTALA_USUNIETA),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_TURY) ,
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_TURY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(document).on('click','.edit-status-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_ZMIENIC_STATUS_TURY),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_ZMIEN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('rounds_edit_status', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(STATUS_TURY_ZOSTAL_ZMIENIONY),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_ZMIENIC_STATUSU_TURY),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_ZMIANE_STATUSU_TURY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(document).on('click','.edit-display-priority-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_CHCESZ_ZMIENIC_PRIORYTET_WYSWIETLANIA_TURY),
            text: trans(WPROWADZ_NOWY_PRIORYTET),
            input: 'number',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_ZMIEN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                if(result.value){
                    $.ajax({
                        beforeSend: function (){
                            loader.show()
                        },
                        type: 'post',
                        url: Routing.generate('rounds_edit_display_priority', {id: id, val: result.value}),
                        complete: function (){
                            loader.hide()
                        }
                    }).done(d => {
                        if (!d.error)
                        {
                            Swal.fire({
                                icon: 'success',
                                title: trans(PRIORYTET_WYSWIETLANIA_TURY_ZOSTAL_ZMIENIONY),
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                confirmButtonColor: '#3f51b5',
                            }).then(() => {
                                location.reload()
                            } )
                        }
                        else
                        {
                            Swal.fire({
                                icon: 'error',
                                title: trans(NIE_UDALO_SIE_ZMIENIC_PRIORYTETU_WYSWIETLANIA_TURY),
                                text: d.error,
                                confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                confirmButtonColor: '#3f51b5',
                            })
                        }

                    })
                }
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_ZMIANE_PRIORYTETU_WYSWIETLANIA_TURY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


}

window.initRoundList = () => {
    roundList()
}