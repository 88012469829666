import { datatableInit } from "../functions/datatableInit"
import Swal from "sweetalert2";

import {
    trans,
    CZY_NA_PEWNO_USUNAC_NAZWE_TURY,
    NIE_ANULUJ,
    TAK_USUN,
    NAZWA_TURY_ZOSTALA_USUNIETA,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_USUNAC_NAZWY_TURY,
    ANULOWANO_USUWANIE_NAZWY_TURY,

} from '../../../translator';
const loader = window.customLoader

const roundNameList = () => {

    $(document).on('click','.remove-round-name', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_USUNAC_NAZWE_TURY),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('rounds_name_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(NAZWA_TURY_ZOSTALA_USUNIETA),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            $("#table-refresh-round-name").load(location.href + " #table-refresh-round-name")
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_NAZWY_TURY),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_NAZWY_TURY),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(() => {

        datatableInit('.datatable')

        $('.hasTooltip').tooltip()

    })

}

window.initRoundNameList = () => {
    roundNameList()
}