import {setLocale, getLocale} from "@symfony/ux-translator";

const routes = require('./routes.json')
const Routing = require('../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.js')

window.$ = window.jQuery = require('jquery')

Routing.setRoutingData(routes)

window.Routing = Routing;

// template
require('./template/core')

// libs
require('./template/libs/bootstrap.bundle.min')
require('datatables.net')
require('./template/libs/select2.min')

// custom
require('./custom')

// styles
require('../scss/index.scss')

//import {trans, TEST} from '../translator'

// UNCOMMENT FOR TRANSLATION TEST
//alert(trans(TEST));