//global
require('./global/global')

// pages
require('./pages/userList')
require('./pages/userForm')
require('./pages/customerList')
require('./pages/customerForm')
require('./pages/scaleList')
require('./pages/scaleForm')
require('./pages/questionnaireForm')
require('./pages/locationForm')
require('./pages/locationList')
require('./pages/featuresLocationList')
require('./pages/featuresLocationForm')
require('./pages/roundForm')
require('./pages/roundLocationsForm')
require('./pages/roundList')
require('./pages/auditList')
require('./pages/correctiveActionList')
require('./pages/reportLocation')
require('./pages/questionForm')
require('./pages/fillAuditStepOne')
require('./pages/fillAuditAnswerQuestion')
require('./pages/auditDetails')
require('./pages/reportLocationSum')
require('./pages/reportQuestion')
require('./pages/correctiveActionCard')
require('./pages/questionnaireList')
require('./pages/roundNameList')
require('./pages/auditAdd')
require('./pages/correctiveActionListClient')
require('./pages/auditListClient')
require('./pages/auditListClientLocation')
require('./pages/planReportSend')
require('./pages/planReportSendList')

//parts
require('./parts/loader')
require('./parts/passwordValidation')