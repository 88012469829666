import {select2Init} from "../functions/select2Init";

import {
    trans,
    ZAPISZ,
    PRZEJDZ_DO_KROKU_TRZECIEGO,




} from '../../../translator';
const roundForm = () => {

    $(document).on('change', 'select[data-bind=location]', function (){

        const value = $(this).val(), button = $('#round_submit')

        button.html(value === '0' ? trans(ZAPISZ) : trans(PRZEJDZ_DO_KROKU_TRZECIEGO) )

    })

    $(() => {

        select2Init('select[data-select=select2]')
        select2Init('select[data-select=select2-no-search]', {
            minimumResultsForSearch: Infinity
        })
    })

}

window.initRoundForm = () => {
    roundForm()
}