import { datatableInit } from "../functions/datatableInit"
import Swal from "sweetalert2"
import flatpickr from "flatpickr"
import {select2Init} from "../functions/select2Init"
import { Polish } from "flatpickr/dist/l10n/pl"
import axios from "axios"


import {
    trans,
    LOKALIZACJA_NIEDOSTEPNA,
    SZCZEGOLY_LOKALIZACJI,
    NUMER_LOKALIZACJI,
    ADRES,
    ZAPLANUJ_AUDYT,
    MUSISZ_WSKAZAC_TYP_PLANOWANIA,
    ZAPLANUJ_AUDYTY,
    DATA_AUDYTU,
    PRZYBLIZONA_DATA_AUDYTU,
    BLAD,
    SUKCES,
    POPRAWNIE_ZAPLANOWANO_AUDYTY,
    POPRAWNIE_ZAPLANOWANO_AUDYT,
    BLAD_SERWERA,
    ANULOWANO_PLANOWANIE_AUDYTU,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_ZMIENIC_AUDYTORA,
    CZY_CHCESZ_EDYTOWAC_AUDYTORA,
    WYBIERZ_AUDYTORA,
    NIE_ANULUJ,
    TAK_ZMIEN,
    AUDYTOR_ZOSTAL_ZMIENIONY,
    ANULOWANO_ZMIANE_AUDYTORA,
    CZY_NA_PEWNO_CHCESZ_USUNAC_AUDYT,
    CZY_NA_PEWNO_CHCESZ_USUNAC_AUDYTY,
    TAK_USUN,
    AUDYT_ZOSTAL_USUNIETY,
    AUDYTY_ZOSTALY_USUNIETE,
    COS_POSZLO_NIE_TAK,
    ANULOWANO_USUWANIE_AUDYTU,
    ANULOWANO_USUWANIE_AUDYTOW,
    CZY_NA_PEWNO_ZREZYGNOWAC,
    ANULUJ,
    TAK_ZREZYGNUJ,
    POPRAWNIE_ZREZYGNOWANO_Z_AUDYTU,
    BRAK,
    KOD_POCZTOWY,
    NUMER_TELEFONU,
    MAKROREGION,
    REGION,
    OKREG,
    REJON,






} from '../../../translator';

// import weekSelectPlugin from "flatpickr/dist/plugins/weekSelect/weekSelect";

const loader = window.customLoader

const auditList = () => {

    async function audit(id, locationId)
    {
        let flatPickerInstance
        let locationHtml

        if (id !== 0 && locationId !== 0){

            loader.show()

            const locationDetails = await axios.get(Routing.generate('location_details', {id: locationId})).then(r => r.data)

            locationHtml = `
            <hr>
            <h3>${trans(SZCZEGOLY_LOKALIZACJI)}</h3>
            <p>${trans(NUMER_LOKALIZACJI)} ${locationDetails.number}</p>
            <p>${trans(ADRES)} ${locationDetails.address}, ${locationDetails.zipCode} ${locationDetails.city}</p>
            <p>${locationDetails.unavailable.length > 0 ? trans(LOKALIZACJA_NIEDOSTEPNA)  + ': ' + locationDetails.unavailable : ''}</p>
            <hr>
            `

            loader.hide()
        }
        else {
            locationHtml = ''
        }

        Swal.fire({
            icon: 'question',
            title: id === 0 ? trans(ZAPLANUJ_AUDYTY) : trans(ZAPLANUJ_AUDYT) + `#${id}`,
            allowOutsideClick: false,
            template: '#plan-template',
            reverseButtons: true,
            willOpen: () => {
                select2Init('.select2-swal')
                select2Init('.select2-swal-nosearch', {
                    minimumResultsForSearch: Infinity
                })
                Swal.getPopup().querySelector('#locationDetails').innerHTML = locationHtml

                $('.select2-swal+.select2-container').css("width", "90%").css("margin", "1em 0.1em")
                $('.select2-swal-nosearch+.select2-container').css("width", "90%").css("margin", "1em 0.1em")
            },
            preConfirm: () => {
                let auditor = Swal.getPopup().querySelector('#audit_auditor').value,
                    planType = Swal.getPopup().querySelector('#audit_planType').value

                if (!planType)
                {
                    Swal.showValidationMessage(trans(MUSISZ_WSKAZAC_TYP_PLANOWANIA))
                }
                if (!auditor)
                {
                    auditor = null;
                }


                return {
                    auditor: auditor,
                    planType: planType
                }
            }

        }).then( async d => {

            if (d.isConfirmed)
            {

                let from = null, to = null
                let roundFrom = null, roundTo = null

                loader.show()

                axios.get(Routing.generate('location_inaccessibility', {id: id})).then(async r => {
                    if (r.data.dates) {
                        from = r.data.dates.from
                        to = r.data.dates.to
                        roundFrom = r.data.dates.roundFrom
                        roundTo = r.data.dates.roundTo
                    }
                    loader.hide()

                    const auditorId = d.value.auditor
                    let datePlanned, dateComment, swalValue

                    if (d.value.planType === "0") {
                        swalValue = await Swal.fire({
                            icon: 'question',
                            title: id === 0 ? trans(ZAPLANUJ_AUDYTY) : trans(ZAPLANUJ_AUDYT) + ` #${id}`,
                            allowOutsideClick: false,
                            template: '#plan-template-exact',
                            reverseButtons: true,
                            willOpen: () => {
                                flatPickerInstance = flatpickr(
                                    Swal.getPopup().querySelector('.datePlanned'), {
                                        locale: Polish,
                                        minDate: roundFrom,
                                        maxDate: roundTo,
                                        disable: [
                                            {
                                                from: from,
                                                to: to,
                                            }
                                        ]
                                    }
                                )
                            },
                            preConfirm: () => {
                                const datePlanned = Swal.getPopup().querySelector('#audit_exact_datePlanned').value

                                if (!datePlanned) {
                                    Swal.showValidationMessage(trans(DATA_AUDYTU))
                                }

                                return {
                                    datePlanned: datePlanned,
                                }
                            }
                        })
                    } else {
                        swalValue = await Swal.fire({
                            icon: 'question',
                            title: id === 0 ? trans(ZAPLANUJ_AUDYTY)   : trans(ZAPLANUJ_AUDYT) + ` #${id}`,
                            allowOutsideClick: false,
                            template: '#plan-template-approximate',
                            reverseButtons: true,
                            willOpen: () => {
                                flatPickerInstance = flatpickr(Swal.getPopup().querySelector('.dateComment'), {
                                        locale: Polish,
                                        mode: "range",
                                        minDate: roundFrom,
                                        maxDate: roundTo,
                                        disable: [
                                            {
                                                from: from,
                                                to: to,
                                            }
                                        ]
                                        // plugins: [new weekSelectPlugin()],
                                        // onChange: [function(){
                                        //     // extract the week number
                                        //     // note: "this" is bound to the flatpickr instance
                                        //     const weekNumber = this.selectedDates[0]
                                        //         ? this.config.getWeek(this.selectedDates[0])
                                        //         : null;
                                        //
                                        //     const year = this.selectedDates[0].getFullYear()
                                        //
                                        //     Swal.getPopup().querySelector('.dateComment').value = `${weekNumber} tydzień ${year}`
                                        // }]
                                    }
                                )
                            },
                            preConfirm: () => {
                                const dateComment = Swal.getPopup().querySelector('#audit_approximate_dateComment').value

                                if (!dateComment) {
                                    Swal.showValidationMessage(trans(PRZYBLIZONA_DATA_AUDYTU))
                                }

                                return {
                                    dateComment: dateComment,
                                }
                            }
                        })
                    }

                    datePlanned = swalValue.value.datePlanned ? swalValue.value.datePlanned : ''
                    dateComment = swalValue.value.dateComment ? swalValue.value.dateComment : ''

                    loader.show()

                    const data = new FormData();

                    data.append('datePlanned', datePlanned)
                    data.append('dateComment', dateComment)
                    data.append('auditor', auditorId)

                    if (id === 0) {

                        const ids = []
                        const checked = document.querySelectorAll('input[name=checkbox-audit]:checked')

                        checked.forEach(check => {
                            ids.push(check.dataset.id)
                        })
                        data.append('ids', ids)

                    }

                    axios.post(Routing.generate('audit_plan', {id: id}), data, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }).then( r => {
                        const data = r.data
                        let icon, title, content, reload

                        if (data.error)
                        {
                            icon = 'error'
                            title = trans(BLAD)
                            content = data.error
                            reload = false
                        }
                        else
                        {
                            icon = 'success'
                            title = trans(SUKCES)
                            content = id === 0 ? trans(POPRAWNIE_ZAPLANOWANO_AUDYTY)  : trans(POPRAWNIE_ZAPLANOWANO_AUDYT)
                            reload = true
                        }

                        loader.hide()

                        Swal.fire({
                            icon: icon,
                            title: title,
                            text: content,
                            allowOutsideClick: !reload
                        }).then( () => {

                            if (reload)
                            {
                                $('.datatable').DataTable().ajax.reload()
                            }
                        })

                    }).catch(() => {
                        loader.hide()

                        Swal.fire({
                            icon: 'error',
                            title: trans(BLAD) ,
                            text: trans(BLAD_SERWERA)
                        }).then(null)

                    })
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_PLANOWANIE_AUDYTU),
                    confirmButtonColor: '#25b372',
                    allowOutsideClick: false
                }).then(null)
            }

        })
    }

    function auditDate(id)
    {
        let flatPickerInstance
        let from = null, to = null
        let roundFrom = null, roundTo = null

        loader.show()

        axios.get(Routing.generate('location_inaccessibility', {id: id})).then(async r => {
            if (r.data.dates)
            {
                from = r.data.dates.from
                to = r.data.dates.to
                roundFrom = r.data.dates.roundFrom
                roundTo = r.data.dates.roundTo
            }
            loader.hide()

            let datePlanned

            await Swal.fire({
                icon: 'question',
                title: trans(ZAPLANUJ_AUDYT) + ` #${id}`,
                allowOutsideClick: false,
                template: '#plan-template-exact',
                reverseButtons: true,
                willOpen: () => {
                    flatPickerInstance = flatpickr(
                        Swal.getPopup().querySelector('.datePlanned'), {
                            locale: Polish,
                            minDate: roundFrom,
                            maxDate: roundTo,
                            disable: [
                                {
                                    from: from,
                                    to: to,
                                }
                            ]
                        }
                    )
                },
                preConfirm: () => {
                    const datePlanned = Swal.getPopup().querySelector('#audit_exact_datePlanned').value

                    if (!datePlanned) {
                        Swal.showValidationMessage(trans(DATA_AUDYTU))
                    }

                    return {
                        datePlanned: datePlanned,
                    }
                }
            }).then(result => {
                if (result.isConfirmed)
                {
                    datePlanned = result.value.datePlanned

                    loader.show()

                    const data = new FormData();

                    data.append('datePlanned', datePlanned)
                    data.append('type', 1)

                    if (id === 0) {

                        const ids = []
                        const checked = document.querySelectorAll('input[name=checkbox-audit]:checked')

                        checked.forEach(check => {
                            ids.push(check.dataset.id)
                        })
                        data.append('ids', ids)

                    }

                    axios.post(Routing.generate('audit_plan', {id: id}), data, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }).then( r => {
                        const data = r.data
                        let icon, title, content, reload

                        if (data.error)
                        {
                            icon = 'error'
                            title = trans(BLAD)
                            content = data.error
                            reload = false
                        }
                        else
                        {
                            icon = 'success'
                            title = trans(SUKCES)
                            content = data.text ? data.text : trans(POPRAWNIE_ZAPLANOWANO_AUDYT)
                            reload = true
                        }

                        loader.hide()

                        Swal.fire({
                            icon: icon,
                            title: title,
                            text: content,
                            allowOutsideClick: !reload
                        }).then( () => {

                            if (reload)
                            {
                                $('.datatable').DataTable().ajax.reload()
                            }

                        })

                    }).catch(() => {
                        loader.hide()

                        Swal.fire({
                            icon: 'error',
                            title: trans(BLAD),
                            text: trans(BLAD_SERWERA)
                        }).then(null)

                    })
                }
                else
                {
                    Swal.fire({
                        icon: 'warning',
                        title: trans(ANULOWANO_PLANOWANIE_AUDYTU),
                        confirmButtonColor: '#3f51b5',
                        confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    })
                }
            })
        })
    }

    $(document).on('click', '.audit-edit', function (){
        const id = $(this).data('id'), locationId = $(this).data('location')

        $.ajax({
            type: 'post',
            url: Routing.generate('audit_template_refresh', {id: id})
        })

        audit(id, locationId).then(null)
    })

    $(document).on('click', '.audit-plan', function (){

        const id = $(this).data('id'), locationId = $(this).data('location')

        audit(id, locationId).then(null)
    })

    $(document).on('click', '.audits-plan-date', function ()
    {
        auditDate(0)
    })

    $(document).on('click', '.audit-plan-date', function ()
    {
        const id = $(this).data('id')

        auditDate(id)
    })


    $(document).on('click','.audit-edit-auditor', function() {

        const data = new FormData();
        const ids = []
        const id = $(this).data('id')
        if(id){
            ids.push(id)
        }else{
            const checked = document.querySelectorAll('input[name=checkbox-audit]:checked')

            checked.forEach(check => {
                ids.push(check.dataset.id)
            })
        }
        data.append('ids', ids)

        let auditors = []

        $.ajax({
            type: 'post',
            url: Routing.generate('user_auditor_list'),
        }).done(d => {

            if (d.error)
            {
                Swal.fire({
                    icon: 'error',
                    title: trans(NIE_UDALO_SIE_ZMIENIC_AUDYTORA) ,
                    text: d.error,
                    confirmButtonText: trans(ZAMKNIJ_BUTTON) ,
                    confirmButtonColor: '#3f51b5',
                })

            } else{
                const out = Object.values(d)
                for (let i in out){
                    auditors[out[i].id] = out[i].fullname
                }

                Swal.fire({
                    icon: 'question',
                    title: trans(CZY_CHCESZ_EDYTOWAC_AUDYTORA),
                    input: 'select',
                    inputOptions: auditors,
                    inputPlaceholder: trans(WYBIERZ_AUDYTORA),
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showCancelButton: true,
                    cancelButtonText: trans(NIE_ANULUJ),
                    showConfirmButton: true,
                    confirmButtonText: trans(TAK_ZMIEN),
                    cancelButtonColor: '#6c757d',
                    confirmButtonColor: '#dc3545'
                }).then(result => {
                    if (result.isConfirmed)
                    {
                        if(result.value){

                            axios.post(Routing.generate('audit_edit_auditor', {val: result.value}), data, {
                            }).then( r => {
                                const d = r.data

                                if (!d.error)
                                {
                                    Swal.fire({
                                        icon: 'success',
                                        title: trans(AUDYTOR_ZOSTAL_ZMIENIONY),
                                        showConfirmButton: true,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                        confirmButtonColor: '#3f51b5',
                                    }).then(() => {
                                        $('.datatable').DataTable().ajax.reload()
                                    } )
                                }
                                else
                                {
                                    Swal.fire({
                                        icon: 'error',
                                        title: trans(NIE_UDALO_SIE_ZMIENIC_AUDYTORA),
                                        text: d.error,
                                        confirmButtonText: trans(ZAMKNIJ_BUTTON),
                                        confirmButtonColor: '#3f51b5',
                                    })
                                }

                            })
                        }

                    }
                    else
                    {
                        Swal.fire({
                            icon: 'warning',
                            title: trans(ANULOWANO_ZMIANE_AUDYTORA),
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                })
            }
        })

    })


    $(document).on('click', '.remove-audit', function () {
        const data = new FormData();
        const ids = []
        const id = $(this).data('id')
        if(id){
            ids.push(id)
        }else{
            const checked = document.querySelectorAll('input[name=checkbox-audit]:checked')

            checked.forEach(check => {
                ids.push(check.dataset.id)
            })
        }
        data.append('ids', ids)

        Swal.fire({
            icon: 'question',
            title: ids.length === 1? trans(CZY_NA_PEWNO_CHCESZ_USUNAC_AUDYT)  : trans(CZY_NA_PEWNO_CHCESZ_USUNAC_AUDYTY),
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            confirmButtonColor: '#dc3545',
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            cancelButtonColor: '#6c757d'
        }).then(result => {
            if (result.isConfirmed)
            {
                axios.post(Routing.generate('audit_remove'), data, {
                }).then( r => {
                    const d = r.data

                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: ids.length === 1? trans(AUDYT_ZOSTAL_USUNIETY) : trans(AUDYTY_ZOSTALY_USUNIETE),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then( () => {
                            $('.datatable').DataTable().ajax.reload()
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title:trans(COS_POSZLO_NIE_TAK),
                            html: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then( () => {
                            $('.datatable').DataTable().ajax.reload()
                        })
                    }
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: ids.length === 1? trans(ANULOWANO_USUWANIE_AUDYTU): trans(ANULOWANO_USUWANIE_AUDYTOW),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })

    $(document).on('click', '.export-audit', function () {

        const url = (Routing.generate('audit_export'))
        window.open(url, '_blank')
    })


    $(document).on('click', '.audit-abandon', function () {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'warning',
            title: trans(CZY_NA_PEWNO_ZREZYGNOWAC) + ` #${id}?`,
            showCancelButton: true,
            cancelButtonText: trans(ANULUJ),
            cancelButtonColor: '#ef5350',
            reverseButtons: true,
            confirmButtonText: trans(TAK_ZREZYGNUJ),
            confirmButtonColor: '#25b372',
            allowOutsideClick: false
        }).then(r => {

            if (r.isConfirmed)
            {
                loader.show()

                axios.delete(Routing.generate('audit_abandon', {id: id})).then(r => r.data).then(d => {

                    let icon, title, text, reload

                    if (d.error)
                    {
                        icon = 'error'
                        title = trans(BLAD)
                        text = d.error
                        reload = false
                    }
                    else
                    {
                        icon = 'success'
                        title = trans(SUKCES)
                        text = trans(POPRAWNIE_ZREZYGNOWANO_Z_AUDYTU)
                        reload = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: text,
                        allowOutsideClick: !reload
                    }).then( () => {

                        if (reload)
                        {
                            $('.datatable').DataTable().ajax.reload()
                        }

                    })

                })
            }

        })

    })

    $(document).on('click', '.export-pdf', function () {
        const id = $(this).data('id')
        const typ = 1
        const url = (Routing.generate('audit_details_pdf', {id: id, typ: typ}))
        window.open(url, '_blank')
    })

    $('.datatable tbody').on('click', '.open-shop', function () {

        loader.show()

        const id = $(this).data('id')
        const tr = $(this).closest('tr')
        const row = $('.datatable').DataTable().row( tr );

        if ( row.child.isShown() ) {
            row.child.hide();
            tr.removeClass('shown');

            loader.hide()
        }
        else {
            $.ajax({
                type: 'post',
                url: Routing.generate('location_details', {id: id}),
            }).then(result => {

                if (result['number'] == null)
                {
                    result['number'] = trans(BRAK)
                }
                if (result['address'] == null)
                {
                    result['address'] = trans(BRAK)
                }
                if (result['city'] == null)
                {
                    result['city'] = trans(BRAK)
                }
                if (result['zipCode'] == null)
                {
                    result['zipCode'] = trans(BRAK)
                }
                if (result['phoneNumber'] == null)
                {
                    result['phoneNumber'] = trans(BRAK)
                }
                if (result['macroregion'] == null)
                {
                    result['macroregion'] = trans(BRAK)
                }
                if (result['region'] == null)
                {
                    result['region'] = trans(BRAK)
                }
                if (result['district'] == null)
                {
                    result['district'] = trans(BRAK)
                }
                if (result['area'] == null)
                {
                    result['area'] = trans(BRAK)
                }

                row.child(

                    '<table class="location-info" style="padding-left:50px;">' +
                    '<tr>' +
                    '<td class="text-center">trans(NUMER_LOKALIZACJI)</td>' +
                    '<td class="text-center">trans(ADRES)</td>' +
                    '<td class="text-center">trans(KOD_POCZTOWY)</td>' +
                    '<td class="text-center">trans(NUMER_TELEFONU)</td>' +
                    '<td class="text-center">trans(MAKROREGION)</td>' +
                    '<td class="text-center">trans(REGION)</td>' +
                    '<td class="text-center">trans(OKREG)</td>' +
                    '<td class="text-center">trans(REJON)</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td class="text-center">' + result['number'] + '</td>' +
                    '<td class="text-center">' + result['address'] + ' ' + result['city'] +'</td>' +
                    '<td class="text-center">' + result['zipCode'] + '</td>' +
                    '<td class="text-center">' + result['phoneNumber'] + '</td>' +
                    '<td class="text-center">' + result['macroregion'] + '</td>' +
                    '<td class="text-center">' + result['region'] + '</td>' +
                    '<td class="text-center">' + result['district'] + '</td>' +
                    '<td class="text-center">' + result['area'] + '</td>' +
                    '</tr>' +
                    '</table>'

                ).show();
                tr.addClass('shown');

                loader.hide()
            })
        }
    } );

    function checkCheckboxes() {
        const checkboxes = $('.checkbox-audit:checkbox:checked')

        if (checkboxes.length > 0) {
            $('#plan-audit').removeClass('disabled')
            $('#plan-audit-date').removeClass('disabled')
            $('#remove-audits').removeClass('disabled')
            $('#audits-edit-auditor').removeClass('disabled')
        }
        else {
            $('#plan-audit').addClass('disabled')
            $('#plan-audit-date').addClass('disabled')
            $('#remove-audits').addClass('disabled')
            $('#audits-edit-auditor').addClass('disabled')

        }
    }

    $(document).on('click', '.checkbox-audit', function () {
        checkCheckboxes()
    })

    $(document).on('click', '#select-all', function () {
        const check = $(this).is(':checked')
        const checkboxes = document.getElementsByName('checkbox-audit');

        checkboxes.forEach((item) => {
            item.checked = check
        })
        checkCheckboxes()
    })

    $(document).on('click', '.plan-audit-checked', function () {

        audit(0, 0)
    })

    $(document).keypress(function(event){
        let keycode = (event.keyCode ? event.keyCode : event.which);
        if(keycode == '13'){
            $('.datatable').DataTable().ajax.reload();
        }
    });

    $('#search-filters').on('click', function () {

        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', function () {
        $('#customer').val('').change();
        $('#number').val('');
        $('#macroregion').val('').change();
        $('#region').val('').change();
        $('#district').val('').change();
        $('#area').val('').change();
        $('#roundname').val('').change();
        $('#questionnaire').val('').change();
        $('#auditor').val('').change();
        $('#feature').val('').change();
        $('#date-planned-from').val('');
        $('#date-planned-to').val('');
        $('#date-implementation-from').val('');
        $('#date-implementation-to').val('');
        $('#result-from').val('');
        $('#result-to').val('');
        $('#status').val('').change('');
            $('.datatable').DataTable().ajax.reload()
    })

    $(() => {
        datatableInit('.datatable', {
            columns: [
                { name: 'checkbox', 'orderable': false},
                { name: 'a.id'},
                { name: 'l.number'},
                { name: 'l.address'},
                { name: 'n.name'},
                { name: 'q.name'},
                { name: 'ad.name'},
                { name: 'a.id'},
                { name: 'a.datePlanned'},
                { name: 'a.dateImplementation'},
                { name: 'a.result'},
                { name: 'a.status'},
                { name: 'actions', 'orderable': false},
            ],
            order: [1, 'desc'],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            lengthMenu: [50,100,250,500],
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                data: function ( d ) {
                    d.customer = $('#customer :selected').data('customer')
                    d.number = $('#number').val()
                    d.macroregion = $('#macroregion :selected').data('macroregion');
                    d.region = $('#region :selected').data('region');
                    d.district = $('#district :selected').data('district');
                    d.area = $('#area :selected').data('area');
                    d.roundname = $('#roundname :selected').data('roundname');
                    d.questionnaire = $('#questionnaire :selected').data('questionnaire');
                    d.auditor = $('#auditor :selected').data('auditor');
                    d.feature = $('#feature :selected').data('feature');
                    d.datePlannedFrom = $('#date-planned-from').val();
                    d.datePlannedTo = $('#date-planned-to').val();
                    d.dateImplementationFrom = $('#date-implementation-from').val();
                    d.dateImplementationTo = $('#date-implementation-to').val();
                    d.resultFrom = $('#result-from').val();
                    d.resultTo = $('#result-to').val();
                    d.status = $('#status :selected').data('status');
                },
                url: Routing.generate('audit_list_ajax'),
                type: "POST",
                dataType: 'JSON',
                complete: function (){
                    loader.hide()
                    checkCheckboxes()
                }
            },
        })
    })

    select2Init('select[data-audit-select=audit-select2]')

    select2Init('select[data-select=select2-none-search]', {
        minimumResultsForSearch: Infinity
    })
}

window.initAuditList = () => {
    auditList()
}