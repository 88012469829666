import { select2Init } from "../functions/select2Init";
import Swal from "sweetalert2";
import Sortable from 'sortablejs'

import {
    trans,
    TRWA_LADOWANIE,
    CZY_NA_PEWNO_USUNAC_OBSZAR,
    SPOWODUJE_TO_USUNIECIE,
    NIE_ANULUJ,
    TAK_USUN,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_USUNAC_OBSZARU,
    ANULOWANO_USUWANIE_OBSZARU,
    CZY_NA_PEWNO_USUNAC_PYTANIE,
    SPOWODUJE_TO_USUNIECIE_WSZYSTKICH_PYTAN,
    PYTANIE_ZOSTALO_USUNIETE,
    NIE_UDALO_SIE_USUNAC_PYTANIA,
    ANULOWANO_USUWANIE_PYTANIA,

} from '../../../translator';

const questionnaireForm = () => {

    const loader = window.customLoader

    $('.areas-list').each( function () {
        const sortable = Sortable.create(this, {
            filter: '.no-sort',
            onChange: function()
            {
                let sequence = this.toArray()
                $.ajax({
                    type: 'post',
                    url: Routing.generate('surveys_survey_set_sequence'),
                    data: {
                        sequence: sequence
                    }
                })
            },
            handle: '.handle',
            animation: 150,
        })
    })

    $('.questions-list').each( function () {
        const sortable = Sortable.create(this, {
            filter: '.no-sort',
            onChange: function()
            {
                let sequence = this.toArray()

                $.ajax({
                    type: 'post',
                    url: Routing.generate('surveys_survey_set_sequence_question'),
                    data: {
                        sequence: sequence
                    }
                })
            },
            handle: '.handle-question',
            animation: 150,
        })
    })

    $(document).on('submit', '#areaModal', function (e) {
        e.preventDefault();

        const parent = $(this).parents('.modal-body')
        const modal = parent.parents('.modal')
        const id = parent.data('id')
        const at = parent.data('at')

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">trans(TRWA_LADOWANIE) </p></div>`)

        $.ajax({
            type: 'post',
            url:  Routing.generate('surveys_survey_edit_area', {at: at, id: id}),
            data: $(this).serialize()
        }).done(d => {
            if (d.ok)
            {
                modal.modal('hide')
                $("#areas-refresh").load(location.href + " #areas-refresh")
            }
            else if (d.form)
            {
                parent.html(d.form)
            }
        })

    })

    $('#modal-area-form').on('shown.bs.modal', function (event) {

        const id = $(event.relatedTarget).data('id')
        const at = $(event.relatedTarget).data('at')
        const modal = $(this)
        const body = modal.find('.modal-body')

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">trans(TRWA_LADOWANIE)</p></div>`)

        $.ajax({
            type: 'get',
            url: Routing.generate('surveys_survey_edit_area', {at:at, id:id})
        }).done((d) => {

            if (d.form)
            {
                body.html(d.form)
                body.attr('data-at', at)
                body.attr('data-id', id)
                select2Init('.select2')
            }
        })
    })

    $(document).on('click','.remove-area', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_USUNAC_OBSZAR),
            text: trans(SPOWODUJE_TO_USUNIECIE),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove_area', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        $("#areas-refresh").load(location.href + " #areas-refresh")

                        Swal.fire({
                            icon: 'success',
                            title: trans(OBSZAR_ZOSTAL_USUNIETY),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_OBSZARU),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_OBSZARU),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('submit', '#questionModal', async function (e) {
        e.preventDefault();

        const parent = $(this).parents('.modal-body')
        const modal = parent.parents('.modal')
        const id = parent.data('id')
        const at = parent.data('at')

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">trans(TRWA_LADOWANIE)</p></div>`)

        $.ajax({
            type: 'post',
            url:  Routing.generate('surveys_survey_edit_question', {at: at, id: id}),
            data: $(this).serialize()
        }).done(d => {
            if (d.ok)
            {
                modal.modal('hide')
                $("#areas-refresh").load(location.href + " #areas-refresh")
            }
            else if (d.form)
            {
                parent.html(d.form)
            }
        })

    })

    $('#modal-question-form').on('shown.bs.modal', function (event) {

        const id = $(event.relatedTarget).data('id')
        const at = $(event.relatedTarget).data('at')
        const modal = $(this)
        const body = modal.find('.modal-body')

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">trans(TRWA_LADOWANIE)</p></div>`)

        $.ajax({
            type: 'get',
            url: Routing.generate('surveys_survey_edit_question', {at:at, id:id})
        }).done((d) => {

            if (d.form)
            {
                body.html(d.form)
                body.attr('data-at', at)
                body.attr('data-id', id)
                select2Init('.select2')
            }
        })
    })

    $(document).on('click','.remove-question', function() {

        const id = $(this).data('id')
        const area = $(this).data('area')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_USUNAC_PYTANIE),
            text: trans(SPOWODUJE_TO_USUNIECIE_WSZYSTKICH_PYTAN),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove_question', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        $("#questions-refresh-" + area ).load(location.href + " #questions-refresh-" + area)

                        Swal.fire({
                            icon: 'success',
                            title: trans(PYTANIE_ZOSTALO_USUNIETE),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_PYTANIA),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_PYTANIA),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(() => {

        select2Init('.select2')

    })

}

window.initQuestionnaireForm = () => {
    questionnaireForm()
}