import {datatableInit} from "../functions/datatableInit";
import Swal from "sweetalert2";

import {
    trans,
    CZY_NA_PEWNO_CHCESZ_USUNAC_WARUNEK,
    NIE_ANULUJ,
    TAK_USUN,
    WARUNEK_ZOSTAL_USUNIETY,
    ZAMKNIJ_BUTTON,
    NIE_UDALO_SIE_USUNAC_WARUNKU,
    ANULOWANO_USUWANIE_WARUNKU,

} from '../../../translator';


const loader = window.customLoader

const planReportSendList = () => {

    $(document).on('click','.remove-condition', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: trans(CZY_NA_PEWNO_CHCESZ_USUNAC_WARUNEK),
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: trans(NIE_ANULUJ),
            showConfirmButton: true,
            confirmButtonText: trans(TAK_USUN),
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_plan_report_send_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: trans(WARUNEK_ZOSTAL_USUNIETY),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: trans(NIE_UDALO_SIE_USUNAC_WARUNKU),
                            text: d.error,
                            confirmButtonText: trans(ZAMKNIJ_BUTTON),
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: trans(ANULOWANO_USUWANIE_WARUNKU),
                    confirmButtonText: trans(ZAMKNIJ_BUTTON),
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(() => {

        datatableInit('.datatable')

    })
}

window.initPlanReportSendList = () => {
    planReportSendList()
}